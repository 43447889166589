import { DatePicker, Input, Select } from "antd";
import { useFilterDateDropdown } from "hooks/useFilterDateDropdown";
import { useListTitle } from "hooks/useListTitle";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getAllAdditionalSavingsAction, deleteAdditionaSavingsAction } from "store/clients";
import { useQueryParam, NumberParam } from "use-query-params";
import { getSummationOfFields } from "utils";
import { getOrgStaffProfileOrgField } from "utils";
import { getOrgDashboardIdField } from "utils";
import { ModifiedAntTable } from "./ModifiedAntTable";

const AdditionalSavingsList = () => {
  const [filterDate, setFilterDate] = useState([
    moment().subtract(10, "days"),
    moment(),
  ]);
  const [summedData, setSummedData] = useState({});
  const [loading, setLoading] = useState();
  const [waivedBalance, setWaivedBalance] = useState([]);
  const [filteredWaivedBalance, setFilteredWaivedBalance] = useState([]);
  const [filterName, setfilterName] = useState("");
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const dispatch = useDispatch();
  const { type, id } = useParams();
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const { renderFilter } = useFilterDateDropdown({
    setFilterDate,
    menuClassName: "mt-0",
  });
  const { fullListName } = useListTitle("additional savings")

  useEffect(() => {
    setFilteredWaivedBalance(
      waivedBalance.filter((val) =>
        val?.client.fullname
          ?.toLocaleLowerCase()
          .includes(filterName?.toLocaleLowerCase())
      )
    );
  }, [filterName]);

  useEffect(() => {
    setFilteredWaivedBalance(waivedBalance);
  }, [waivedBalance]);

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: filteredWaivedBalance,
        fieldsToSum: ["amount"],
      })
    );
  }, [filteredWaivedBalance]);

  useEffect(() => {
    const userProfileOrg = getOrgStaffProfileOrgField(user.position);

    const orgIdField = getOrgDashboardIdField(type || user.position);
    let org_id = id || user[userProfileOrg]?.id;

    if (user.position === 'CO' && !id) {
      org_id = user.id
    }

    const queryString = `start_date=${filterDate[0].format(
      "YYYY/MM/DD"
    )}&end_date=${filterDate[1].format("YYYY/MM/DD")}&${orgIdField}=${org_id}`;

    setLoading(true);
    dispatch(getAllAdditionalSavingsAction(queryString))
      .then((result) => {
        setWaivedBalance(result);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [filterDate, refreshTrigger]);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this from record?.")) {
      await dispatch(deleteAdditionaSavingsAction(id));
      setRefreshTrigger((current) => !current);
    }
  };

  const tableColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: "Summation",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Amount (₦)",
      dataIndex: "amount",
      render: (text, record, index) => (
        <div>{Number(record?.amount).toLocaleString()}</div>
      ),
      summary: summedData["amount"],
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <Link to={`/clients/${record.client.id}`}>
          <i className="fa fa-link" aria-hidden="true"></i>{" "}
          {`${record?.client.fullname}`}
        </Link>
      ),
    },
  ];

  if (["DR", "SM", "ZM"].includes(user?.position)) {
    tableColumn.push({
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <span role="button" onClick={() => handleDelete(record?.id)}>
          <i className="fas fa-trash"></i>
        </span>
      ),
      summary: "-",
    });
  }

  return (
    <div>
      <h3 className="page-title mb-4">{fullListName}</h3>
      <Input.Group compact className="mb-3 filter-panel">
        <Input
          style={{ width: "95%" }}
          placeholder="Filter by name"
          value={filterName}
          onChange={(e) => setfilterName(e.target.value)}
        />
        <div className="ml-2 mt-1">{renderFilter()}</div>
      </Input.Group>

      <ModifiedAntTable
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={tableColumn}
        dataSource={filteredWaivedBalance}
        bordered
        showSummary={true}
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </div>
  );
};

export { AdditionalSavingsList };
