import { Input, Modal, Select } from "antd";
import { ClientSelectField } from "components/ClientSelectField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showAlertAction } from "store/alert";
import { makeContributionCollection } from "store/loans";
import { makeFieldCollection } from "store/loans";
import { getOrgDashboardIdField } from "utils";

export const FieldPaymentModal = ({
  orgType,
  orgId,
  type = "fieldcollection",
  triggerTitle = "New Field Collection",
  title = "Add a New Field Collection",
}) => {
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [isOpen, setIsOpen] = useState(false);
  const [client, setClient] = useState();
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.users);

  const dispatch = useDispatch();

  const handleMakePayment = async () => {
    setLoading(true)
    if (loading) {
      return
    }

    if (!amount || !client) {
      dispatch(showAlertAction({
        message: "Please fill in the input fields correctly",
        level: "danger",
        isVisible: true,
      }));
      return;
    }

    if (type === "contribution") {
      await dispatch(
        makeContributionCollection({
          amount,
          payment_method: paymentMethod,
          client_id: client,
          description,
          collector_id: user?.id,
        })
      );
    } else {
      await dispatch(
        makeFieldCollection({
          amount,
          payment_method: paymentMethod,
          client_id: client,
          description,
          collector_id: user?.id,
        })
      );
    }

    toggleModal();
    setClient();
    setAmount("");
    setDescription("");
    setLoading(false);
  };

  const toggleModal = () => {
    setIsOpen((open) => !open);
  };

  return (
    <>
      <div
        className="badge badge-outline badge-pill pointer"
        onClick={toggleModal}
        role="button"
      >
        {triggerTitle}
      </div>

      <Modal
        visible={isOpen}
        onCancel={() => toggleModal()}
        title={title}
        okText={loading ? "Submitting..." : "Submit"}
        onOk={handleMakePayment}
        okButtonProps={{ className: "ant-modal-action-btn" }}
      >
        <ClientSelectField onSelect={setClient} defaultQueryParam={`${getOrgDashboardIdField(orgType)}=${orgId}`} />

        <div className="d-flex gap-2">
          <Input
            placeholder="Amount"
            className="form-control mb-2"
            value={amount}
            type="number"
            onChange={(e) => setAmount(e.target.value)}
          />

          <Select
            className="default-ant-select default-ant-select-40"
            placeholder="Select Payment Method"
            value={paymentMethod}
            onChange={setPaymentMethod}
          >
            <Select.Option value={"cash"}>Cash</Select.Option>
            <Select.Option value={"transfer"}>Transfer</Select.Option>
          </Select>
        </div>

        <Input.TextArea
          placeholder="Notes"
          className="form-control mb-2"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Modal>
    </>
  );
};
