import { createSlice } from "@reduxjs/toolkit";
import { BASE_API_URL } from "db";
import moment from "moment";
import { getErrorMsg, makeRequest } from "utils";
import { showAlertAction } from "./alert";

const slice = createSlice({
  name: "loans",
  initialState: {
    loading: false,
    filterDate: [moment().subtract(5, "days"), moment()],
    loans: {
      branches: {},
      areas: {},
      zones: {},
      states: {},
    },
    loadingDailyPay: false,
    dailyPay: {},
    loadingSavings: false,
    dailySavings: {}
  },
  reducers: {
    addNewClientSuccess: (state, action) => {
      state.registeredClients.push(action.payload);
    },
    updateFilterDate: (state, action) => {
      state.filterDate = action.payload;
    },
    setLoans: (state, action) => {
      state.loans = {
        ...state.loans,
        [action.payload?.orgType]: {
          ...state.loans?.[action.payload?.orgType],
          [action.payload?.id]: action.payload.data,
        },
      };
    },
    setDailyPay: (state, action) => {
      state.dailyPay = {
        ...state.dailyPay,
        [action.payload?.orgType]: {
          ...state.dailyPay?.[action.payload?.orgType],
          [action.payload?.id]: action.payload.data,
        },
      }
    },
    setDailySavings: (state, action) => {
      state.dailySavings = {
        ...state.dailySavings,
        [action.payload?.orgType]: {
          ...state.dailySavings?.[action.payload?.orgType],
          [action.payload?.id]: action.payload.data,
        },
      }
    },
    setLoadingDailyPay: (state, action) => {
      state.loadingDailyPay = action.payload
    },
    setLoadingDailySavings: (state, action) => {
      state.loadingDailyPay = action.payload
    }
  },
});

export default slice.reducer;

const { updateFilterDate, setLoans, setDailyPay, setLoadingDailyPay, setDailySavings, setLoadingDailySavings } = slice.actions;

export const setDateFilterAction = (dateRange) => async (dispatch) => {
  try {
    dispatch(updateFilterDate(dateRange));
  } catch (error) {
    return getErrorMsg(error?.message);
  }
};

export const validateLoanRequest =
  ({ client, guarantor }) =>
  async (dispatch) => {
    try {
      const httpResponse = await makeRequest(
        `${BASE_API_URL}/loans/request/validate?client=${client}&guarantor=${guarantor}`,
        "GET"
      );
      // Get an org based on ID from endpoint
      return httpResponse;
    } catch (error) {
      return getErrorMsg(error?.message);
    }
  };

export const shouldPayLoanWaiverFeeAction =
  (client_id) =>
  async (dispatch) => {
    try {
      const httpResponse = await makeRequest(
        `${BASE_API_URL}/loans/request/should-pay-waiver/?client_id=${client_id}`,
        "GET"
      );
      // Get an org based on ID from endpoint
      return httpResponse;
    } catch (error) {
      return getErrorMsg(error?.message);
    }
  };

export const newLoanRequest = (data) => async (dispatch) => {
  try {
    const httpResponse = await makeRequest(
      `${BASE_API_URL}/loans/create/`,
      "POST",
      data
    );

    dispatch(
      showAlertAction({
        message: "Congratulations 🎉, your loan request has been queued",
        level: "success",
        isVisible: true,
      })
    );
    // Get an org based on ID from endpoint
    return httpResponse;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getPersistedLoansWithParams =
  (orgType, id, queryString) => async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/loans/?${queryString}`,
        "GET"
      );

      dispatch(
        setLoans({
          orgType,
          id,
          data: res,
        })
      );
    } catch (e) {
      dispatch(
        showAlertAction({
          message: "Unable to retrieve loan data please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getLoansWithParams =
  (queryString) => async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/loans/?${queryString}`,
        "GET"
      );
      return res;
    } catch (e) {
      dispatch(
        showAlertAction({
          message: "Unable to retrieve loan data please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getDaiyPayWithParams = (queryString, id, orgType) => async (dispatch) => {
  try {
    dispatch(setLoadingDailyPay(true))
    const res = await makeRequest(
      `${BASE_API_URL}/loans/daily_pay/list/?${queryString}`,
      "GET"
    );

    dispatch(
      setDailyPay({
        orgType,
        id,
        data: res,
      })
    );
    dispatch(setLoadingDailyPay(false))
  } catch (e) {
    dispatch(
      showAlertAction({
        message: "Unable to retrieve loan data please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getDailySavingsWithParams = (queryString, id, orgType) => async (dispatch) => {
  try {
    dispatch(setLoadingDailyPay(false))
    const res = await makeRequest(
      `${BASE_API_URL}/loans/daily_savings/list/?${queryString}`,
      "GET"
    );
    
    dispatch(
      setDailySavings({
        orgType,
        id,
        data: res,
      })
    );
    dispatch(setLoadingDailySavings(false))
  } catch (e) {
    dispatch(
      showAlertAction({
        message: "Unable to retrieve loan data please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getLoan = (id) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(`${BASE_API_URL}/loans/${id}`, "GET");
    return res;
  } catch (e) {
    dispatch(
      showAlertAction({
        message: "Unable to retrieve loan data please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const deleteLoan = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/loans/${id}`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Loan has been deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (e) {
    console.log(e);
    dispatch(
      showAlertAction({
        message: "Loan has been deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  }
};

export const confirmPayment = (data) => async (dispatch, getState) => {
  try {
    let paymentData = data;
    if (!data.approval_date) {
      paymentData = {
        ...data,
        approval_date: moment().format("YYYY-MM-DD"),
      }
    }
    await makeRequest(
      `${BASE_API_URL}/loans/daily_pay/approve/${paymentData?.id}`,
      "PUT",
      paymentData
    );

    dispatch(
      showAlertAction({
        message: "Payment confirmed",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const confirmSavingsPayment =
  (savingsData) => async (dispatch, getState) => {
    try {
      await makeRequest(
        `${BASE_API_URL}/loans/daily_savings/approve/${savingsData?.id}`,
        "PUT",
        savingsData
      );

      dispatch(
        showAlertAction({
          message: "Payment confirmed",
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const waivePaymentAction = (data) => async (dispatch, getState) => {
  try {
    if (data?.should_waive) {
      await makeRequest(
        `${BASE_API_URL}/loans/payment/waive/`,
        "POST",
        data
      );

      dispatch(
        showAlertAction({
          message: "Waiver successful",
          level: "success",
          isVisible: true,
        })
      );
      return
    } else {
      await makeRequest(
        `${BASE_API_URL}/loans/payment/unwaive/`,
        "POST",
        data
      );

      dispatch(
        showAlertAction({
          message: "Unwaive successful",
          level: "success",
          isVisible: true,
        })
      );
    }

  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const confirmBatchPayment =
  ({ ids }) =>
  async (dispatch, getState) => {
    try {
      await makeRequest(
        `${BASE_API_URL}/loans/daily_pay/approve_batch/`,
        "POST",
        { ids: ids }
      );

      dispatch(
        showAlertAction({
          message: "Payments confirmed",
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const confirmBatchSavings =
  ({ ids }) =>
  async (dispatch, getState) => {
    try {
      await makeRequest(
        `${BASE_API_URL}/loans/daily_savings/approve_batch/`,
        "POST",
        { ids: ids }
      );

      dispatch(
        showAlertAction({
          message: "Payments confirmed",
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const disbursePayment = (id, paymentData) => async (dispatch) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/loans/disburse/${id}`,
      "POST",
      paymentData
    );

    dispatch(
      showAlertAction({
        message: "Disbursement confirmed",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

/**
 * Assumes that client's last loan is also their active loan.
 * @param {String} clientId
 * @returns
 */
export const getClientLastLoanAction = (clientId) => async (dispatch) => {
  try {
    const httpRes = await makeRequest(
      `${BASE_API_URL}/loans/?client_id=${clientId}`,
      "GET"
    );

    return httpRes?.[0];
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const createHoliday = (data) => async (dispatch, getState) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/loans/holidays/?state_id=${data?.state_id}`,
      "POST",
      data
    );

    dispatch(
      showAlertAction({
        message: "Holiday created successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getHolidays = (data) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/loans/holidays/?state_id=${data?.state_id || ""}`,
      "GET",
      data
    );

    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const deleteHoliday = (id) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/loans/holidays/${id}/`,
      "DELETE"
    );

    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const closeLoanAction = (id) => async (dispatch, getState) => {
  try {
    await makeRequest(`${BASE_API_URL}/loans/close/${id}/`, "PATCH");

    await dispatch(
      showAlertAction({
        message: "Loan Closed Successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const withdraw = (data) => async (dispatch, getState) => {
  try {
    await makeRequest(`${BASE_API_URL}/accounts/transactions/`, "POST", data);

    await dispatch(
      showAlertAction({
        message: "Withdrawal successful",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllWithdrawals = (querySet) => async (dispatch, getState) => {
  try {
    // Make sure we have a valid query string
    const queryString = querySet ? querySet : '';
    
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/transactions/?${queryString}`,
      "GET"
    );

    // If response doesn't have the paginated structure, convert it
    if (res && !res.results) {
      return {
        results: res,
        count: res.length,
        total_amount: res.reduce((sum, item) => sum + Number(item.amount || 0), 0)
      };
    }

    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
    return { results: [], count: 0, total_amount: 0 }; // Return empty paginated response on error
  }
};


export const getAllClosedLoanRecord = (client_id) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/loans/close-record/${client_id}/`,
      "GET"
    );

    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const makeFieldCollection = (data) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/loans/field-collections/`,
      "POST",
      data
    );

    dispatch(
      showAlertAction({
        message: "Collection recorded successfully",
        level: "success",
        isVisible: true,
      })
    );

    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllFieldCollecionAction =
  (queryString) => async (dispatch) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/loans/field-collections/?${queryString}`
      );

      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const deleteFieldCollecttionAction = (id) => async (dispatch) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/loans/field-collections/${id}/`,
      "DELETE"
    );

    dispatch(
      showAlertAction({
        message: "Successfully deleted",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    console.log(error)
  }
};

export const makeContributionCollection = (data) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/loans/contributions/`,
      "POST",
      data
    );

    dispatch(
      showAlertAction({
        message: "Contribution made successfully",
        level: "success",
        isVisible: true,
      })
    );

    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllContrbutionsAction =
  (queryString) => async (dispatch) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/loans/contributions/?${queryString}`
      );

      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const deleteContributionAction = (id) => async (dispatch) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/loans/contributions/${id}/`,
      "DELETE"
    );

    dispatch(
      showAlertAction({
        message: "Contribution deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};