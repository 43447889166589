import React, { useEffect } from "react";
import { DatePicker, Descriptions, Drawer, Table } from "antd";
import { useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { useQueryParam, NumberParam } from "use-query-params";
import { getTotal, getCashInHand } from "utils";
import { PaymentDistributionDrawer } from "./PaymentDistributionDrawer";
import { ModifiedAntTable } from "./ModifiedAntTable";
import _ from "lodash";
import { getSummationOfFields } from "utils";
import { DownloadButton } from "./Json2CsvButton";

const PaymentDistributionTable = ({
  dataset,
  onDateRangeChange,
  handleApproveRemittance,
  showApproval,
  loadingTableData,
}) => {
  // Defaults to 3 days from now
  const [dateRange, setDateRage] = useState([
    moment().subtract(4, "days"),
    moment(),
  ]);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [activeRecord, setActiveRecord] = useState();
  const [summedData, setSummedData] = useState({});

  const handleDateRageChange = (val) => {
    setDateRage(val);
    onDateRangeChange(val);
  };

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: dataset,
        fieldsToSum: [
          "realizable_installment",
          "realizable_savings",
          "realised_installment",
          "realised_savings",
          "waived_installment",
          "waived_savings",
          "total_reg_fee",
          "total_disbursement_fee",
          "total_union_fee",
          "total_admin_fee",
          "total_waiver_fee",
          "prev_cash_in_hand_paid",
          "approved_before_date_dailypay",
          "approved_before_date_savings",
          "advanced_payment",
          "total_withdrawal_for_dailypay",
          "total_cash_withdrawal",
          "total",
          "cashInHand",
          "special_income",
          "expenses",
          "sold_assets",
          "retrieved_assets",
          "total_waiver_balance",
          "total_additional_savings",
          "total_contribution",
          "total_contribution_fee",
        ],
        fieldWithHandlers: {
          total: (rowInstance) => getTotal(rowInstance),
          cashInHand: (rowInstance) => getCashInHand(rowInstance),
        },
      })
    );
  }, [dataset]);

  const columns = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: "Summations",
    },
    {
      title: "Date",
      dataIndex: "date",
      summary: "-",
    },
    {
      title: "Realizable",
      summary: [
        summedData?.["realizable_installment"],
        summedData?.["realizable_savings"],
      ],
      children: [
        {
          title: "Installment (₦)",
          dataIndex: "realizable_installment",
          key: "realisable_installment",
          render: (_, data) =>
            Number(data?.realizable_installment || 0).toLocaleString(),
        },
        {
          title: "Savings (₦)",
          dataIndex: "realizable_savings",
          key: "savings",
          render: (_, data) =>
            Number(data?.realizable_savings || 0).toLocaleString(),
        },
      ],
    },
    {
      title: "Realised",
      summary: [
        summedData?.["realised_installment"],
        summedData?.["realised_savings"],
      ],
      children: [
        {
          title: "Installment (₦)",
          dataIndex: "realised_installment",
          key: "installment",
          render: (_, data) =>
            Number(data?.realised_installment || 0).toLocaleString(),
        },
        {
          title: "Savings (₦)",
          dataIndex: "realised_savings",
          key: "savings",
          render: (_, data) =>
            Number(data?.realised_savings || 0).toLocaleString(),
        },
      ],
    },
    {
      title: "Contributions (₦)",
      summary: summedData?.["total_contribution"],
      render: (_, record) =>
        Number(record?.total_contribution || 0).toLocaleString(),
    },
    {
      title: "Additional Savings (₦)",
      summary: summedData?.["total_additional_savings"],
      render: (_, record) =>
        Number(record?.total_additional_savings || 0).toLocaleString(),
    },
    {
      title: "Waived installment (₦)",
      summary: summedData?.["waived_installment"],
      render: (_, record) =>
        Number(record?.waived_installment || 0).toLocaleString(),
    },
    {
      title: "Waived savings (₦)",
      summary: summedData?.["waived_savings"],
      render: (_, record) =>
        Number(record?.waived_savings || 0).toLocaleString(),
    },
    {
      title: "Waiver Balance (₦)",
      summary: summedData?.["total_waiver_balance"],
      render: (_, record) =>
        Number(record?.total_waiver_balance || 0).toLocaleString(),
    },
    {
      title: "Registration Fee (₦)",
      summary: summedData?.["total_reg_fee"],
      render: (_, record) =>
        Number(record?.total_reg_fee || 0).toLocaleString(),
    },
    {
      title: "Contribution Fee (₦)",
      summary: summedData?.["total_contribution_fee"],
      render: (_, record) =>
        Number(record?.total_contribution_fee || 0).toLocaleString(),
    },
    {
      title: "Disbursement Fee (₦)",
      summary: summedData?.["total_disbursement_fee"],
      render: (_, record) =>
        Number(record?.total_disbursement_fee || 0).toLocaleString(),
    },
    {
      title: "Union Fee (₦)",
      render: (record) => Number(record?.total_union_fee || 0).toLocaleString(),
      summary: summedData?.["total_union_fee"],
    },
    {
      title: "Admin Fee (₦)",
      render: (record) => Number(record?.total_admin_fee || 0).toLocaleString(),
      summary: summedData?.["total_admin_fee"],
    },
    {
      title: "Waiver fee (₦)",
      render: (record) =>
        Number(record?.total_waiver_fee || 0).toLocaleString(),
      summary: summedData?.["total_waiver_fee"],
    },
    {
      title: "Cash In Hand Recovered (₦)",
      summary: summedData?.["prev_cash_in_hand_paid"],
      render: (_text, data) =>
        Number(data?.prev_cash_in_hand_paid || 0).toLocaleString(),
    },
    // Previously paid in advance
    {
      title: "Arreas payment (₦)",
      summary: summedData?.["approved_before_date_dailypay"],
      render: (_text, data) =>
        Number(data?.approved_before_date_dailypay || 0).toLocaleString(),
    },
    {
      title: "Arreas savings (₦)",
      summary: summedData?.["approved_before_date_savings"],
      render: (_text, data) =>
        Number(data?.approved_before_date_savings || 0).toLocaleString(),
    },
    {
      title: "Advance (₦)",
      summary: summedData?.["advanced_payment"],
      render: (_text, data) =>
        Number(data?.advanced_payment || 0).toLocaleString(),
    },
    {
      title: "Cash Withdrawal (₦)",
      summary: summedData?.["total_cash_withdrawal"],
      render: (_text, data) =>
        Number(data?.total_cash_withdrawal || 0).toLocaleString(),
    },
    {
      title: "Dailypay Withdrawal (₦)",
      summary: summedData?.["total_withdrawal_for_dailypay"],
      render: (_text, data) =>
        Number(data?.total_withdrawal_for_dailypay || 0).toLocaleString(),
    },
    {
      title: "Expenses (₦)",
      dataIndex: "expenses",
      key: "expenses",
      summary: summedData?.["expenses"],
      render: (_text, data) => Number(data?.expenses || 0).toLocaleString(),
    },
    {
      title: "Special Income (₦)",
      dataIndex: "special_income",
      key: "special_income",
      summary: summedData?.["special_income"],
      render: (_text, data) =>
        Number(data?.special_income || 0).toLocaleString(),
    },
    {
      title: "Asset Auction (₦)",
      dataIndex: "sold_assets",
      key: "sold_assets",
      summary: summedData?.["sold_assets"],
      render: (_text, data) => Number(data?.sold_assets || 0).toLocaleString(),
    },
    {
      title: "Asset Retrieve (₦)",
      dataIndex: "retrieved_assets",
      key: "retrieved_assets",
      summary: summedData?.["retrieved_assets"],
      render: (_text, data) =>
        Number(data?.retrieved_assets || 0).toLocaleString(),
    },
    {
      title: "Cash In Hand (₦)",
      dataIndex: "cashInHand",
      key: "cashInHand",
      summary: summedData?.["cashInHand"],
      render: (_text, data) => Number(getCashInHand(data)).toLocaleString(),
    },
    {
      title: "Total (₦)",
      dataIndex: "total",
      key: "total",
      summary: summedData?.["total"],
      render: (_, data) => Number(getTotal(data)).toLocaleString(),
    },
  ];

  if (showApproval) {
    columns.push({
      title: "Remittance",
      dataIndex: "is_remitted",
      key: "remittance_status",
      summary: "-",
      render: (_text, data) => (
        <div
          className={classNames(
            "status-badge badge badge-sm",
            data.is_remitted ? "badge-success" : "badge-warning"
          )}
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            const total = getTotal(data);
            handleApproveRemittance(data, dateRange, total);
          }}
        >
          {data.is_remitted ? "Remitted" : "Approve"}
        </div>
      ),
    });
  }

  return (
    <div className="payment-table">
      <div className="d-flex">
        <DownloadButton data={dataset} text="Download CSV" />

        <DatePicker.RangePicker
          allowClear
          style={{ width: "100%" }}
          value={dateRange}
          onChange={handleDateRageChange}
          className="mb-3"
        />
      </div>

      <ModifiedAntTable
        loading={loadingTableData}
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={dataset}
        bordered
        className="dashboard_table"
        showSummary={true}
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
        onRow={(record, rowIndex) => ({
          onClick: () => setActiveRecord(record),
        })}
      />

      <PaymentDistributionDrawer
        activeRecord={activeRecord}
        setActiveRecord={setActiveRecord}
      />
    </div>
  );
};

export { PaymentDistributionTable };
