import { createSlice } from "@reduxjs/toolkit";
import { getOrgDashboardIdField } from "utils";
import { makeRequest, hashToken } from "utils";
import { BASE_API_URL } from "../db";
import { showAlertAction } from "./alert";

const slice = createSlice({
  name: "users",
  initialState: {
    token: "",
    user: null,
    users: [],
    userDashboardData: null,
    metaData: {},
    loadingDashboard: false,
    loading: false,
  },
  reducers: {
    addNewUser: (state, action) => {
      state.users.push(action.payload);
    },
    logout: (state, action) => {
      state.user = null;
      localStorage.removeItem("user_local_token");
    },
    login: (state, action) => {
      state.user = action.payload;
    },
    setDashboardData: (state, action) => {
      state.userDashboardData = action.payload.userDashboardData;
    },
    setLoadingDashobard: (state, action) => {
      state.loadingDashboard = action.payload;
    },
    setAllUsersSuccess: (state, action) => {
      state.users = action.payload;
    },
    setMetaData: (state, action) => {
      state.metaData = action.payload
    }
  },
});

export default slice.reducer;

const {
  addNewUser,
  logout,
  login,
  setDashboardData,
  setLoadingDashobard,
  setAllUsersSuccess,
  setMetaData
} = slice.actions;

export const handleLoginAction = (authData) => async (dispatch) => {
  try {
    const auth_token = localStorage.getItem("user_local_token");
    const TOKEN_UNAVAILABLE =
      auth_token === "undefined" ||
      auth_token === undefined ||
      auth_token === null;

    const getUserData = async () => {
      const userDetail = await makeRequest(
        `${BASE_API_URL}/auth/users/me/`,
        "GET"
      );
      if (userDetail) {
        dispatch(login(userDetail));
      }
    };

    const setToken = async () => {
      const res = await makeRequest(
        `${BASE_API_URL}/auth/token/login`,
        "POST",
        authData
      );

      if (res instanceof Error) {
        throw res;
      }

      const token_res = res?.auth_token;
      const newToken = hashToken(token_res);
      localStorage.setItem("user_local_token", newToken);
    };

    if (TOKEN_UNAVAILABLE && authData) {
      await setToken()
        .then(() => {
          getUserData();
        })
        .catch((error) => {
          throw error;
        });
    }

    if (!TOKEN_UNAVAILABLE) {
      getUserData();
    }
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const handleLogoutAction = () => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/auth/token/logout`, "POST");
    dispatch(logout());
  } catch (error) {
    dispatch(logout());
  }
};

export const createNewUser = (userData) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/auth/users/`, "POST", userData);
    dispatch(addNewUser(userData));

    dispatch(
      showAlertAction({
        message: "New staff has been added successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const updateUser = (id, userData) => async (dispatch) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/accounts/users/update/${id}/`,
      "PUT",
      userData
    );

    dispatch(
      showAlertAction({
        message: "Information updated successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getUserAction = (userId) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/users/${userId}/`,
      "GET"
    );

    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllUserAction = () => async (dispatch, getState) => {
  try {
    const res = await makeRequest(`${BASE_API_URL}/accounts/users/`, "GET");

    dispatch(setAllUsersSuccess(res));
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const setUserDashboard =
  ({ userId }) =>
  async (dispatch, getState) => {
    try {
      const { user } = getState().users;

      dispatch(setMetaData(user))

      dispatch(setLoadingDashobard(true));
      const orgIdField = getOrgDashboardIdField(user.position);
      const orgId = user.id;

      const figuresRes =
        user.position === "DR"
          ? await makeRequest(`${BASE_API_URL}/org/figures/`, "GET")
          : await makeRequest(
              `${BASE_API_URL}/org/figures/?org_id_field=${orgIdField}&org_id=${orgId}`,
              "GET"
            );

      const getDashboardData = (id) => {
        return {
          accumulations: {
            "Total Registration Fees": `₦ ${
              Number(figuresRes?.total_reg_fee) || 0
            }`,
            "Total Contribution Fees": `₦ ${
              Number(figuresRes?.total_contribution_fee) || 0
            }`,
            "Total Disbursement Fees": `₦ ${
              Number(figuresRes?.total_disbursement_fee) || 0
            }`,
            "Total Completed Loans": figuresRes?.completed_loans || 0,
            "Total Number of Clients": figuresRes?.number_of_clients || 0,
            "Previous accumulated daily pay cash in hand": `₦${(
              Number(figuresRes?.accumulated_pay_cash_in_hand) || 0
            ).toLocaleString()}`,
            "Previous accumulated savings cash in hand": `₦${(
              Number(figuresRes?.accumulated_savings_cash_in_hand) || 0
            ).toLocaleString()}`,
          },
          cardsData: figuresRes,
        };
      };

      const httpUser = getDashboardData(userId);
      await dispatch(
        setDashboardData({
          userDashboardData: httpUser,
        })
      );

      dispatch(setLoadingDashobard(false));
      return true;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getCos = () => async (dispatch, getState) => {
  try {
    const { user } = getState().users;

    const cos = await makeRequest(
      `${BASE_API_URL}/accounts/users/?position=CO`
    );

    return cos;
  } catch (e) {
    return [];
  }
};

export const deleteUserAction = (id) => async (dispatch) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/accounts/users/${id}/`, 'DELETE'
    );

    dispatch(
      showAlertAction({
        message: "Staff deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (e) {
    dispatch(
      showAlertAction({
        message: e?.message || "A erorr occured, please try again.",
        level: "success",
        isVisible: true,
      })
    );
  }
};

export const changeMyPassword = (data) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/auth/users/set_password/`,
      "POST",
      data
    );

    dispatch(
      showAlertAction({
        message: "Password change successful",
        level: "success",
        isVisible: true,
      })
    );
    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};


export const staffSalaryAction =
  (data, pk, type = "POST") =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/salaries/${pk || ""}`,
        type,
        data
      );

      dispatch(
        showAlertAction({
          message: "Successul",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const staffEmploymentStatusAction =
  (data, pk, type = "POST") =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/employment_status/${pk || ""}`,
        type,
        data
      );

      dispatch(
        showAlertAction({
          message: "Successul",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const staffAwardsAction =
  (data, pk, type = "POST") =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/awards/${pk || ""}`,
        type,
        data
      );

      dispatch(
        showAlertAction({
          message: "Successul",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const staffQueriesAction =
  (data, pk, type = "POST") =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/queries/${pk || ""}`,
        type,
        data
      );

      dispatch(
        showAlertAction({
          message: "Successul",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const staffTransfersAction =
  (data, pk, type = "POST") =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/transfers/${pk ?? ""}`,
        type,
        data
      );

      dispatch(
        showAlertAction({
          message: "Successul",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

  export const staffPromotionAction =
  (data, type = "POST") =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/promotions/`,
        type,
        data
      );

      dispatch(
        showAlertAction({
          message: "Successul",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };
