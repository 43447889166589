import { Image, Row, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { useHandleImageUpload } from "hooks/useHandleImageUpload";
import React from "react";
import { IS_PROD } from "utils";

const ImageUpload = ({ image, uploadDir, onImageUpload, onDeleteImage }) => {
  const imageDir = IS_PROD ? uploadDir : `test/${uploadDir}`
  const {
    handleImageChange,
    uploadingImage,
    deletingImage,
    imageUrl,
    setImageUrl
  } = useHandleImageUpload(imageDir, image);
  let loadingImageUpload = false;

  const handleImage = async (file) => {
    if (uploadingImage || deletingImage) {
      return;
    }
    await handleImageChange(file, imageDir).then((url) => {
      if (url && typeof url === "string") {
        onImageUpload?.(url);
      }
    });
  };

  const handleRemoveImage = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (window.confirm("Are you sure you want to remove this image?")) {
      if (deletingImage || (!image && !imageUrl)) {
        return;
      }

      // Clear the image URL from state
      setImageUrl(undefined);
      // Notify parent component
      onDeleteImage?.();
    }
  };

  return (
    <div className="mx-2">
      {uploadingImage ? (
        <Row className="custom_upload_area ml-2">Loading...</Row>
      ) : image || imageUrl ? (
        <div className="d-flex flex-column align items-center">
          <Image width={250} height={250} src={image || imageUrl} />

          <button
            onClick={handleRemoveImage}
            className="btn btn-sm btn-danger btn-round mt-2 image-upload-remove-btn"
          >
            {deletingImage ? "Removing..." : "Remove"}
          </button>
        </div>
      ) : (
        <ImgCrop>
          <Upload
            name="image"
            listType="picture-card"
            className="avatar-uploader p-2"
            maxCount={1}
            width={250}
            height={250}
            disabled={uploadingImage || loadingImageUpload}
            onChange={handleImage}
          >
            <div className="p-1">
              <i className="fa fa-upload" aria-hidden="true"></i>
              Click to Upload Image
            </div>
          </Upload>
        </ImgCrop>
      )}
    </div>
  );
};

export { ImageUpload };
