import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { showAlertAction } from "store/alert";
import { withdraw } from "store/loans";

const reasons = {
  payDaily: "Pay loan repayment",
  // payAllDaily: "Pay all daily repayment",
  cashWithdrawal: "Cash withdrawal",
};

const WithdrawalForm = ({ clientData, userId, handleTriggerRefresh }) => {
  const [form] = Form.useForm();
  const [withdrawalReasons, setWithdrawalReasons] = useState();
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [withdrawAccount, setWithdrawAccount] = useState("savings");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (withdrawalReasons === "cashWithdrawal") {
      setWithdrawalAmount(0);
    }
  }, [withdrawalReasons]);

  const handleWithdraw = async (values) => {
    setLoading(true);
    await dispatch(
      withdraw({
        author: userId,
        client: clientData?.id,
        reason: withdrawalReasons,
        amount: withdrawalAmount,
        account: withdrawAccount,
      })
    )
      .then(() => {
        handleTriggerRefresh();
        setLoading(false);
        form.resetFields();
      })
      .catch(() => setLoading(false));
  };

  return (
    <div>
      {Number(clientData?.savings_balance) + Number(clientData?.union_balance) + Number(clientData?.contribution_balance) >
      0 ? (
        <Form form={form} onFinish={handleWithdraw}>
          <Row>
            <Col md="6">
              <Form.Item
                label="Acount"
                name="account"
                rules={[
                  {
                    required: true,
                    message: "Please select account you want to withdraw from.",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select account"
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(val) => setWithdrawAccount(val)}
                >
                  <Select.Option value="savings" key="savings">
                    Savings
                  </Select.Option>
                  <Select.Option value="contribution" key="contribution">
                    Contribution
                  </Select.Option>
                  {/* <Select.Option value="union" key="union">
                    Union
                  </Select.Option> */}
                </Select>
              </Form.Item>
            </Col>

            <Col md="6">
              <Form.Item
                label="Reason"
                name="reason"
                rules={[
                  {
                    required: true,
                    message: "Please select a withdrawal type",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Withdrawal type"
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(val) => setWithdrawalReasons(val)}
                  onClear={() => setWithdrawalReasons(undefined)}
                  allowClear
                >
                  {Object.keys(reasons).map((val) => (
                    <Select.Option value={val} key={val}>
                      {`${reasons[val]}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {withdrawalReasons === "cashWithdrawal" && (
              <Col md="6">
                <Form.Item
                  name="withdrawal_amount"
                  label="Withdrawal amount"
                  rules={[
                    {
                      required: true,
                      min: 0,
                      message: "Please provide loan amount",
                    },
                  ]}
                >
                  <Input
                    className="form-control"
                    type="number"
                    placeholder="Withdrawal Amount"
                    prefix="₦"
                    onChange={(e) => setWithdrawalAmount(e.target.value)}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Button
                disabled={loading}
                variant="primary"
                className="px-5 btn-sm"
                type="submit"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        "Client does not have an available balance to withdraw from."
      )}
    </div>
  );
};

export { WithdrawalForm };
