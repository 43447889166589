import { Col, DatePicker, Input, Row, Select, Tooltip } from "antd";
import { useFilterDateDropdown } from "hooks/useFilterDateDropdown";
import { useListTitle } from "hooks/useListTitle";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getOrgStaffProfileOrgField } from "utils";
import { getOrgDashboardIdField } from "utils";
import { ModifiedAntTable } from "./ModifiedAntTable";
import { getAllContrbutionsAction } from "store/loans";
import debounce from "lodash.debounce";

const ContributionsList = () => {
  const [filterDate, setFilterDate] = useState([
    moment().subtract(1, "days"),
    moment(),
  ]);
  const [loading, setLoading] = useState();
  const [contributions, setContributions] = useState();
  // const [filterPaymentMethod, setFilterPaymentMethod] = useState();
  const [filterClientName, setFilterClientName] = useState("");
  const [filterCOname, setFilterCOname] = useState("");
  const [filterAmount, setFilterAmount] = useState("");
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 50;
  const dispatch = useDispatch();
  const { type, id } = useParams();
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const { renderFilter } = useFilterDateDropdown({
    setFilterDate,
    menuClassName: "mt-0",
  });
  const { fullListName } = useListTitle("Client Contributions");

  const debouncedSetFilterClientName = useCallback(
    debounce((value) => setFilterClientName(value), 500),
    []
  );

  const debouncedSetFilterCollectorName = useCallback(
    debounce((value) => setFilterCOname(value), 500),
    []
  );

  const debouncedSetFilterAmount = useCallback(
    debounce((value) => setFilterAmount(value), 500),
    []
  );

  const fetchData = (newPage) => {
    const userProfileOrg = getOrgStaffProfileOrgField(user.position);
    const orgIdField = getOrgDashboardIdField(type || user.position);
    let org_id = id || user[userProfileOrg]?.id;

    if (user.position === "CO" && !id) {
      org_id = user.id;
    }

    let queryString = `start_date=${filterDate[0].format(
      "YYYY/MM/DD"
    )}&end_date=${filterDate[1].format(
      "YYYY/MM/DD"
    )}&${orgIdField}=${org_id}&client_name=${filterClientName}&co_name=${filterCOname}&amount=${filterAmount}`;

    if (newPage) {
      queryString = queryString.concat(`&page=${newPage}`)
    } else {
      setPage(1)
    }

    setLoading(true);
    return dispatch(getAllContrbutionsAction(queryString))
      .then((res) => {
        console.log(res)
        setContributions(res);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    fetchData()
  }, [filterDate,
    refreshTrigger,
    filterClientName,
    filterCOname,
    filterAmount])

  const handlePageChange = (newPage) => {
    fetchData(newPage).then(() => setPage(newPage));
  }

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this from record?. Note, this would be deducted from current contribution balance.")) {
      await dispatch(deleteAdditionaSavingsAction(id));
      setRefreshTrigger((current) => !current);
    }
  };

  const getColumns = () => {
    const tableColumn = [
      {
        title: "S/N",
        dataIndex: "id",
        render: (text, record, index) =>
          ((Number(page) || 1) - 1) * pageSize + (index + 1),
        summary: "Summation",
      },
      {
        title: "Date",
        dataIndex: "date",
      },
      {
        title: "Amount (₦)",
        dataIndex: "amount",
        render: (text, record, index) => (
          <div>{Number(record?.amount).toLocaleString()}</div>
        ),
        summary: Number(contributions?.total_amount).toLocaleString(),
      },
      {
        title: "Client",
        dataIndex: "name",
        render: (text, record) => (
          <Link to={`/clients/${record.client.id}`}>
            <i className="fa fa-link" aria-hidden="true"></i>{" "}
            {`${record?.client.fullname}`}
          </Link>
        ),
      },
      {
        title: "Collector",
        dataIndex: "name",
        render: (text, record) => <div>{record?.collector.name}</div>,
      },
    ];
    console.log(user?.position)
    if (["DR", "SM", "ZM"].includes(user?.position)) {
      tableColumn.push({
        title: "",
        dataIndex: "id",
        render: (text, record) => (
          <span role="button" onClick={() => handleDelete(record?.id)}>
            <i className="fas fa-trash"></i>
          </span>
        ),
        summary: "-",
      });
    }

    return tableColumn
  }


  useEffect(() => {
    return () => {
      debouncedSetFilterClientName.cancel();
      debouncedSetFilterCollectorName.cancel();
      debouncedSetFilterAmount.cancel();
    };
  }, [
    debouncedSetFilterClientName,
    debouncedSetFilterCollectorName,
    debouncedSetFilterAmount,
  ]);

  return (
    <div>
      <h3 className="page-title mb-4">{fullListName}</h3>
      <Input.Group compact className="mb-3 filter-panel">
        <Input
          style={{ width: "35%" }}
          placeholder="Filter by client name"
          onChange={(e) => debouncedSetFilterClientName(e.target.value)}
        />
        <Input
          style={{ width: "35%" }}
          placeholder="Filter by collector name"
          onChange={(e) => debouncedSetFilterCollectorName(e.target.value)}
        />
        <Input
          style={{ width: "30%" }}
          placeholder="Filter by amount"
          onChange={(e) => debouncedSetFilterAmount(e.target.value)}
        />
      </Input.Group>

      <Row compact className="mb-3 filter-panel">
        <Col className="d-flex w-100">
          {renderFilter()}
          <DatePicker.RangePicker
            allowClear
            style={{ width: "100%" }}
            onChange={setFilterDate}
            className="mb-3"
            value={filterDate}
          />
        </Col>
      </Row>

      <ModifiedAntTable
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={getColumns()}
        dataSource={contributions?.results}
        bordered
        showSummary={true}
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: pageSize,
          hideOnSinglePage: true,
          onChange: handlePageChange,
          total: contributions?.count
        }}
      />
    </div>
  );
};

export { ContributionsList };
