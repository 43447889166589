import { Descriptions, Tabs, Calendar, Form, Select, Input, Image } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { refreshSavingsAction } from "store/clients";
import { confirmSavingsPayment } from "store/loans";
import { waivePaymentAction } from "store/loans";
import { disbursePayment } from "store/loans";
import { confirmPayment } from "store/loans";
import { getLoan } from "store/loans";

import RefreshImg from "assets/img/arrows-rotate-solid.svg";
import EditImg from "assets/img/edit.png";
import WaiveImg from "assets/img/waving.png";
import { InputModal } from "./InputModal";
import { updateClient } from "store/clients";
import { updateGuarantor } from "store/clients";
import { ImageUpload } from "components/ImageUpload";
import { cleanedImage } from "utils";
import { ClosableLoanNotice } from "components/ClosableLoanNotice";
import { refreshUionBalanceAction } from "store/clients";

const { TabPane } = Tabs;

const LoanDetailPage = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loanData, setLoanData] = useState({});
  const [isMobile, setIsMobile] = useState(true);
  const [selectedValue, setSelectedValue] = useState();
  const [validDateRange, setValidDateRage] = useState();
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const ALLOW_WAIVE = ["DR", "SM", "ZM", "AM"].includes(user.position);
  const isDisbursedWithoutPaymentPlan =
    loanData?.disbursed &&
    (!loanData?.daily_savings_data?.length ||
      !loanData?.daily_payment_data?.length);

  const handleUpload = (isClient, data) => {
    if (isClient) {
      dispatch(updateClient(loanData?.client?.id, data, "PATCH")).then(() => {
        setTriggerRefresh((current) => !current);
      });
    } else {
      dispatch(updateGuarantor(loanData?.guarantor?.id, data, "PATCH")).then(
        () => {
          setTriggerRefresh((current) => !current);
        }
      );
    }
  };

  const waiveSingleOrRemainingPayments = async (data) => {
    if (
      window.confirm(
        "Are you sure you want to waive/unwaive daily payments/savings?"
      )
    ) {
      setLoading(true);
      dispatch(waivePaymentAction(data)).then(() => {
        setTriggerRefresh((current) => !current);
      });
    }
  };

  const handleRefreshSavingsOrUnion = async (type, id) => {
    setLoading(true);
    if (type === "union") {
      if (
        window.confirm(
          "Are you sure you want to empty the union balance?. Note: This can not be reversed"
        )
      ) {
        dispatch(refreshUionBalanceAction(id)).then(() => {
          setLoading(false);
          setTriggerRefresh((current) => !current);
        });
      }

      return;
    }

    if (
      window.confirm(
        "Are you sure you want to empty the savings balance?. Note: This can not be reversed"
      )
    ) {
      dispatch(refreshSavingsAction(id)).then(() => {
        setLoading(false);
        setTriggerRefresh((current) => !current);
      });
    }
  };

  const handleChangeSavingsOrUnionBalance = async (value, type, id) => {
    setLoading(true);
    if (type === "union") {
      dispatch(refreshUionBalanceAction(id, value)).then(() => {
        setLoading(false);
        setTriggerRefresh((current) => !current);
      });

      return;
    }

    dispatch(refreshSavingsAction(id, value)).then(() => {
      setLoading(false);
      setTriggerRefresh((current) => !current);
    });
  };

  useEffect(async () => {
    setLoading(true);
    await dispatch(getLoan(id)).then((result) => setLoanData(result));
    setLoading(false);
  }, [triggerRefresh]);

  useEffect(() => {
    if (!loanData?.daily_payment_data) {
      return;
    }
    const startDate = moment(loanData?.daily_payment_data?.[0]?.date);
    const endDate = moment(
      loanData?.daily_payment_data?.[loanData?.daily_payment_data?.length - 1]
        ?.date
    );
    setValidDateRage([startDate, endDate]);
  }, [loanData?.daily_payment_data]);

  useEffect(() => {
    if (window.innerWidth > 700) {
      setIsMobile(false);
    }
  }, []);

  const handleClickUnpaid = async (dateData) => {
    if (
      window.confirm(
        "Do you want to confirm this payment? Note: This oparation cannot be undone."
      )
    ) {
      await dispatch(
        confirmPayment({
          ...dateData,
          paid: true,
          approval_date: moment().format("YYYY-MM-DD"),
        })
      );
      setTriggerRefresh((current) => !current);
    }
  };

  const handleClickUnpaidSavings = async (dateData) => {
    if (
      window.confirm(
        "Do you want to confirm this savings payment? Note: This oparation cannot be undone."
      )
    ) {
      await dispatch(
        confirmSavingsPayment({
          ...dateData,
          paid: true,
          approval_date: moment().format("YYYY-MM-DD"),
        })
      );
      setTriggerRefresh((current) => !current);
    }
  };

  const handleDisburse = async (values) => {
    setLoading(true);
    await dispatch(disbursePayment(id, { ...values, id }));
    setTriggerRefresh((current) => !current);
    setLoading(false);
  };

  const renderLoanDetail = () => (
    <>
      {/* <ClosableLoanNotice loan={loanData} /> */}
      <div className="d-flex flex-column mb-2 mr-0 mr-sm-3">
        <strong>Client</strong>

        <Row>
          <ImageUpload
            uploadDir="clients"
            image={cleanedImage(loanData?.client?.image1)}
            onImageUpload={(url) => handleUpload(true, { image1: url })}
            onDeleteImage={() => handleUpload(true, { image1: null })}
          />

          <ImageUpload
            uploadDir="clients"
            image={cleanedImage(loanData?.client?.image2)}
            onImageUpload={(url) => handleUpload(true, { image2: url })}
            onDeleteImage={() => handleUpload(true, { image2: null })}
          />
        </Row>
      </div>

      <div className="d-flex flex-column mb-2">
        <strong>Guarantor</strong>
        <Row>
          <ImageUpload
            uploadDir="gurantors"
            image={cleanedImage(loanData?.guarantor?.image1)}
            onImageUpload={(url) => handleUpload(false, { image1: url })}
            onDeleteImage={() => handleUpload(false, { image1: null })}
          />

          <ImageUpload
            uploadDir="gurantors"
            image={cleanedImage(loanData?.guarantor?.image2)}
            onImageUpload={(url) => handleUpload(false, { image2: url })}
            onDeleteImage={() => handleUpload(false, { image2: null })}
          />
        </Row>
      </div>

      <Descriptions title="Loan Info" layout="horizontal">
        <Descriptions.Item label="Credit Officer">
          {loanData?.client?.co_fullname || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Client">
          {loanData?.client?.fullname || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Guarantor">
          {loanData?.guarantor
            ? `${loanData?.guarantor?.surname || "-"} ${
                loanData?.guarantor?.firstname || "-"
              }`
            : ""}
        </Descriptions.Item>
        {loanData?.payment_method && (
          <Descriptions.Item label="Payment Method">
            {loanData?.payment_method || "-"}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Cheque Number">
          {loanData?.cheque_number || "-"}
        </Descriptions.Item>
        {loanData.loan_status && (
          <Descriptions.Item label="Disbursement Status">
            {loanData.loan_status === "AWAITING DISBURSMENT"
              ? "PENDING"
              : "DISBURSED"}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Loan Status">
          {loanData.loan_status || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Request Date">
          {loanData.creation_date || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Repayment Start Date">
          {loanData.repayment_start_date || "-"}
        </Descriptions.Item>

        <Descriptions.Item label="Payment Plan">
          {loanData.repayment_plan || "-"}
        </Descriptions.Item>

        <Descriptions.Item label="Duration">
          {loanData.loan_duration || "-"}{" "}
          {loanData.repayment_plan === "daily" ? "Days" : "Weeks"}
        </Descriptions.Item>
        <Descriptions.Item label="Verified Name">
          {loanData.verified_name || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Marital Status">
          {loanData.marital_status || "-"}
        </Descriptions.Item>

        <Descriptions.Item label="House Address" span={2}>
          {loanData.house_address || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Office Address" span={2}>
          {loanData.office_address || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Guarantor Phone Number 1" span={2}>
          {loanData?.guarantor?.phone_number1 || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Guarantor Phone Number 2" span={2}>
          {loanData?.guarantor?.phone_number2 || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Guarantor House Address" span={2}>
          {loanData.guarantor_house_address || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Guarantor Office Address" span={2}>
          {loanData.guarantor_office_address || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Guarantor NIN" span={2}>
          {loanData?.guarantor?.nin || "-"}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

  const renderNumbers = () => (
    <Descriptions title="Loan Numbers" layout="horizontal">
      <Descriptions.Item label="Union Fee">
        ₦ {Number(loanData?.union_fee)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Amount">
        ₦ {Number(loanData?.loan_amount)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Savings Amount">
        ₦ {Number(loanData.savings_amount)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Interest Rate">
        {Number(loanData.interest_rate)?.toLocaleString() || "-"} %
      </Descriptions.Item>
      <Descriptions.Item label="Net Pay">
        ₦ {Number(loanData.net_pay)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Daily Pay">
        ₦ {Number(loanData.daily_pay)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Disbursement Fee">
        ₦ {Number(loanData.disbursment_fee)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Loan Interest">
        ₦ {Number(loanData.interest)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Admin Fee">
        ₦ {Number(loanData.admin_fee)?.toLocaleString() || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Waiver Fee">
        ₦ {Number(loanData.waiver_fee)?.toLocaleString() || "-"}
      </Descriptions.Item>
    </Descriptions>
  );

  const isInvalidDate = (date) => {
    if (!validDateRange || !date) {
      return true;
    }

    return (
      !date.isBetween(validDateRange[0], validDateRange[1], "day", "[]") ||
      date.day() === 6 ||
      date.day() === 0
    );
  };

  const dateCellRender = useCallback(
    (date) => {
      const payment_data = loanData.daily_payment_data;
      const formatedDate = date.format("YYYY-MM-DD");
      const date_data = payment_data?.find((val) => val?.date === formatedDate);

      if (
        isInvalidDate(date) ||
        loanData?.loan_status === "AWAITING DISBURSMENT" ||
        !date_data
      ) {
        return undefined;
      }

      return (
        <span className="calendar_envents events">
          {date_data.waived ? (
            <span
              onClick={() =>
                waiveSingleOrRemainingPayments({
                  id,
                  is_daily_pay: true,
                  should_waive: false,
                  pay_id: date_data?.id,
                })
              }
            >
              <i className="fas fa-info-circle warning_event"></i>
            </span>
          ) : date_data?.paid ? (
            moment(date_data?.approval_date).isAfter(
              moment(date_data?.date)
            ) ? (
              <i className="fas fa-check error_event"></i>
            ) : (
              <i className="fas fa-check success_event"></i>
            )
          ) : (
            <>
              <span
                className="mr-sm-2"
                onClick={() => handleClickUnpaid(date_data)}
              >
                <i className="fas fa-exclamation-triangle error_event"></i>
              </span>

              <span
                className="mt-3"
                onClick={() =>
                  waiveSingleOrRemainingPayments({
                    id,
                    is_daily_pay: true,
                    should_waive: true,
                    pay_id: date_data?.id,
                  })
                }
              >
                <img src={WaiveImg} alt="imgage" height={20} width={20} />
              </span>
            </>
          )}
        </span>
      );
    },
    [validDateRange, loanData]
  );

  const dateSavingsCellRender = useCallback(
    (date) => {
      const payment_data = loanData?.daily_savings_data;
      const formatedDate = date.format("YYYY-MM-DD");
      const date_data = payment_data?.find((val) => val?.date === formatedDate);

      if (
        isInvalidDate(date) ||
        loanData?.loan_status === "AWAITING DISBURSMENT" ||
        !date_data
      ) {
        return undefined;
      }

      return (
        <span className="events">
          {date_data.waived ? (
            <span
              onClick={() =>
                waiveSingleOrRemainingPayments({
                  id,
                  is_daily_pay: false,
                  should_waive: false,
                  pay_id: date_data?.id,
                })
              }
            >
              <i className="fas fa-info-circle warning_event"></i>
            </span>
          ) : date_data?.paid ? (
            <i className="fas fa-check success_event"></i>
          ) : (
            <>
              <span
                className="mr-sm-2"
                onClick={() => handleClickUnpaidSavings(date_data)}
              >
                <i className="fas fa-exclamation-triangle error_event"></i>
              </span>

              <span
                className="mt-3"
                onClick={() =>
                  waiveSingleOrRemainingPayments({
                    id,
                    is_daily_pay: false,
                    should_waive: true,
                    pay_id: date_data?.id,
                  })
                }
              >
                <img src={WaiveImg} alt="imgage" height={20} width={20} />
              </span>
            </>
          )}
        </span>
      );
    },
    [validDateRange, loanData]
  );

  return (
    <Container fluid footer={false}>
      <div>
        <h6 className="mb-3">
          Client: <strong>{loanData?.client?.fullname || "-"}</strong>
        </h6>
        <h6 className="mb-3 d-flex align-items-center">
          Savings Balance: ₦
          {Number(loanData?.client?.savings_balance).toLocaleString()}
          {["DR", "SM", "ZM"].includes(user?.position) && (
            <div>
              <img
                src={RefreshImg}
                alt=""
                height={15}
                width={15}
                className="ml-2"
                role="button"
                onClick={() =>
                  handleRefreshSavingsOrUnion("savings", loanData?.client?.id)
                }
              />

              <InputModal
                value={loanData?.client?.savings_balance}
                onSubmit={(val) =>
                  handleChangeSavingsOrUnionBalance(
                    val,
                    "savings",
                    loanData?.client?.id
                  )
                }
              >
                <img
                  src={EditImg}
                  alt="edit"
                  height={15}
                  width={15}
                  className="ml-2"
                  role="buton"
                />
              </InputModal>
            </div>
          )}
        </h6>
        <h6 className="mb-3 d-flex align-items-center">
          Union Balance: ₦
          {Number(loanData?.client?.union_balance).toLocaleString()}
          {["DR", "SM", "ZM"].includes(user?.position) && (
            <div>
              <img
                src={RefreshImg}
                alt=""
                height={15}
                width={15}
                className="ml-2"
                role="button"
                onClick={() =>
                  handleRefreshSavingsOrUnion("union", loanData?.client?.id)
                }
              />

              <InputModal
                value={loanData?.client?.savings_balance}
                onSubmit={(val) =>
                  handleChangeSavingsOrUnionBalance(
                    val,
                    "union",
                    loanData?.client?.id
                  )
                }
              >
                <img
                  src={EditImg}
                  alt="edit"
                  height={15}
                  width={15}
                  className="ml-2"
                  role="buton"
                />
              </InputModal>
            </div>
          )}
        </h6>
        <h6 className="mb-3">
          Waiver Balance: ₦
          {Number(loanData?.client?.client_waiver_balance).toLocaleString()}
        </h6>
      </div>

      {isDisbursedWithoutPaymentPlan && (
        <div className="alert alert-danger mt-4" role="alert">
          This loan has been disbursed but does not include savings or loan
          repayments, or the savings amount provided is invalid. Please
          re-disburse to correct this issue.
        </div>
      )}

      <Tabs
        tabPosition={isMobile ? "top" : "left"}
        defaultActiveKey="1"
        className="loan-detail-tabs"
      >
        <TabPane tab="Loan Detail" key="1">
          {renderLoanDetail()}
        </TabPane>
        <TabPane tab="Loan Numbers" key="2">
          {renderNumbers()}
        </TabPane>
        <TabPane tab="Savings Payment" key="3">
          <ClosableLoanNotice
            loan={loanData}
            onCloseLoan={() => setTriggerRefresh((current) => !current)}
          />
          <div>
            <span>
              Total Paid days:{" "}
              <strong>{loanData?.savings_paid_days_count}</strong>
            </span>{" "}
            <span>
              Total Unpaid days:{" "}
              <strong>{loanData?.savings_unpaid_days_count}</strong>
            </span>
            {ALLOW_WAIVE && loanData?.savings_unpaid_days_count && (
              <>
                {!!loanData?.waived_savings_count ? (
                  <Button
                    onClick={() =>
                      waiveSingleOrRemainingPayments({
                        id,
                        is_daily_pay: false,
                        should_waive: false,
                      })
                    }
                    disabled={loading}
                    size="sm"
                    variant="secondary"
                    className="mt-2 mt-sm-0 ml-sm-2"
                  >
                    Unwaive all waived savings
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      waiveSingleOrRemainingPayments({
                        id,
                        is_daily_pay: false,
                        should_waive: true,
                      })
                    }
                    disabled={loading}
                    size="sm"
                    variant="secondary"
                    className="mt-2 mt-sm-0 ml-sm-2"
                  >
                    Waive all savings payments
                  </Button>
                )}
              </>
            )}
          </div>
          <Calendar
            onSelect={(val) => setSelectedValue(val)}
            mode="month"
            dateCellRender={dateSavingsCellRender}
            disabledDate={(currentDate) =>
              currentDate.day() === 6 || currentDate.day() === 0
            }
            defaultValue={moment(loanData?.daily_savings_data?.[0]?.date)}
          />
        </TabPane>
        <TabPane tab="Loan Repayment" key="4">
          <ClosableLoanNotice
            loan={loanData}
            onCloseLoan={() => setTriggerRefresh((current) => !current)}
          />
          <div>
            <span>
              Total Paid days: <strong>{loanData?.paid_days_count}</strong>
            </span>{" "}
            <span>
              Total Unpaid days: <strong>{loanData?.unpaid_days_count}</strong>
            </span>
            {ALLOW_WAIVE && loanData?.unpaid_days_count && (
              <>
                {loanData?.waived_pay_count ? (
                  <Button
                    onClick={() =>
                      waiveSingleOrRemainingPayments({
                        id,
                        is_daily_pay: true,
                        should_waive: false,
                      })
                    }
                    size="sm"
                    variant="secondary"
                    className="mt-2 mt-sm-0 ml-sm-2"
                  >
                    Unwaive all waived payments
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      waiveSingleOrRemainingPayments({
                        id,
                        is_daily_pay: true,
                        should_waive: true,
                      })
                    }
                    size="sm"
                    variant="secondary"
                    className="mt-2 mt-sm-0 ml-sm-2"
                  >
                    Waive remainig payment
                  </Button>
                )}
              </>
            )}
          </div>
          <Calendar
            onSelect={(val) => setSelectedValue(val)}
            mode="month"
            dateCellRender={dateCellRender}
            disabledDate={(currentDate) =>
              currentDate.day() === 6 || currentDate.day() === 0
            }
            defaultValue={moment(loanData.daily_payment_data?.[0]?.date)}
          />
        </TabPane>
        <TabPane tab="Disbursement" key="5">
          {loanData?.disbursed && !isDisbursedWithoutPaymentPlan ? (
            <Descriptions title="Disbursed" layout="horizontal">
              <Descriptions.Item label="Disbursement Method">
                {loanData?.payment_method}
              </Descriptions.Item>
              {loanData?.cheque_number && (
                <Descriptions.Item label="Cheque Number">
                  {loanData?.cheque_number}
                </Descriptions.Item>
              )}
            </Descriptions>
          ) : (
            <Form
              form={form}
              onFinish={handleDisburse}
              initialValues={{
                payment_method: loanData?.payment_method,
                cheque_number: loanData?.cheque_number,
                savings_amount: loanData?.savings_amount,
                repayment_start_date: loanData?.repayment_start_date,
              }}
            >
              <Row>
                <Col className="" md="6">
                  <Form.Item
                    name="payment_method"
                    label="Payment Method"
                    rules={[
                      {
                        required: true,
                        message: "Please provide payment method.",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select payment method"
                      optionFilterProp="children"
                      className="custom-input"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      disabled={!!loanData?.payment_method}
                    >
                      <Select.Option value="cheque" key="Cheque">
                        Cheque
                      </Select.Option>
                      <Select.Option value="cash" key="Cash">
                        Cash
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col className="" md="6">
                  <Form.Item
                    name="cheque_number"
                    label="If Cheque, Provide cheque number"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Cheque number"
                      disabled={!!loanData?.cheque_number}
                      type="text"
                      className="form-control"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Form.Item
                    className="d-flex flex-column text-left"
                    name="repayment_start_date"
                    label="Repayment start date"
                    rules={[
                      {
                        required: true,
                        message: "Please select repayment start date",
                      },
                    ]}
                  >
                    <Input
                      disabled={!!loanData?.repayment_start_date}
                      placeholder="Repayment start date"
                      type="date"
                      className="form-control"
                    />
                  </Form.Item>
                </Col>

                {!loanData?.savings_amount && (
                  <Col md="6">
                    <Form.Item
                      className="d-flex flex-column text-left"
                      name="savings_amount"
                      label="Savings Amount"
                      rules={[
                        {
                          required: true,
                          message: "Please select repayment start date",
                        },
                        {
                          validator: async (_, value) => {
                            if (value < 200) {
                              return Promise.reject(
                                "Savings amount must be at least 200"
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        placeholder="Savings amount"
                        type="number"
                        className="form-control"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Row>
                <Col md="6">
                  <Form.Item
                    className="d-flex align-items-center checkbox-form-item"
                    name="disbursed"
                    label="Click to confirm that client has recieved disbursement"
                    labelAlign="left"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        message: "Please confirm disbursement",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "initial" }}
                      type="checkbox"
                      className="form-control-checkbox ml-2"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button
                    disabled={loading}
                    variant="primary"
                    className="px-5"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </TabPane>
      </Tabs>
    </Container>
  );
};

export { LoanDetailPage };
